<template>
  <div class="billInfo">
    <div class="opdetcru">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/bill/billList' }"
          >全部账单</el-breadcrumb-item
        >
        <el-breadcrumb-item>账单详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="basic-info flex">
      <div style="display: flex; flex: 1">
        <div class="user-info">
          <div class="head-pic">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="head-bd">
            <span class="name mr_20">{{ billInfo.real_name }}</span>
            <span class="node">{{ billInfo.id_card_no }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabbarsa mt_10">
      <el-tabs
        class="elTabs"
        type="card"
        :value="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="账单信息" name="账单信息" />
        <el-tab-pane
          label="分期明细"
          name="分期明细"
          v-if="billStageList.length != 0"
        />
      </el-tabs>
    </div>
    <div class="deitem" v-if="activeName == '账单信息'">
      <div class="progress">
        <div class="prosteps" v-if="approveList && approveList.length">
          <el-steps :active="1" finish-status="success" process-status="finish">
            <el-step
              v-for="(item, index) in approveList"
              :key="index"
              :status="finishStatus[item.approve_status]"
            >
              <template #title>
                <span v-if="item.approve_node == 0">账单申请</span>
                <span v-if="item.approve_node == 1">资料审核</span>
                <span v-if="item.approve_node == 2">商家确认</span>
                <span v-if="item.approve_node == 3">分期成功</span>
              </template>
              <template #description>
                <div>{{ item.approve_time }}</div>
                <div v-if="item.approve_reason != ''">
                  审核原因：{{ item.approve_reason }}
                </div>
                <div v-if="item.approve_node == 1 && item.approve_status == 0">
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="isApproveAuth ? false : true"
                    @click="onApproveData(item)"
                    >审核资料</el-button
                  >
                </div>
                <div
                  v-if="
                    item.approve_node == 2 &&
                    item.approve_status == 0 &&
                    approveList[1].approve_status == 1
                  "
                >
                  <el-button
                    type="primary"
                    size="small"
                    :disabled="isApproveAuth ? false : true"
                    @click="onApproveInstitution(item)"
                    >审核</el-button
                  >
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
        <div class="refundInfo">
          <div v-if="billInfo.bill_status == 4 || billInfo.bill_status == 5">
            <el-button
              type="primary"
              v-if="
                infoBusiness.refund_bill == 1 && billInfo.refund_status == 0
              "
              :disabled="isRefundAuth ? false : true"
              @click="onRefund"
              >发起退款</el-button
            >
          </div>
          <div v-if="billInfo.refund_status == 1">
            <div class="refundTitle">已申请退款</div>
            <el-button @click="onCancelRefund">取消退款</el-button>
          </div>
          <div v-if="billInfo.refund_status == 2">
            <div class="refundTitle">已退款</div>
            <el-button @click="getCheckRedund">查看</el-button>
          </div>
        </div>
      </div>
      <el-card shadow="hover" class="cardInfo">
        <el-descriptions
          class="margin-top"
          labelClassName="labelc"
          contentClassName="connamc"
          title="个人信息"
          :column="2"
          size="medium"
          border
        >
          <el-descriptions-item label="姓名">{{
            billInfo.real_name
          }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{
            billInfo.mobile
          }}</el-descriptions-item>
          <el-descriptions-item label="身份证号">{{
            billInfo.id_card_no
          }}</el-descriptions-item>
          <el-descriptions-item label="实名认证">
            <span v-if="billInfo.real_auth_status == 0">未认证</span>
            <span v-if="billInfo.real_auth_status == 1">已认证</span>
          </el-descriptions-item>
          <el-descriptions-item label="学历">{{
            billInfo.education
          }}</el-descriptions-item>
          <el-descriptions-item label="现居住地址">{{
            billInfo.residence_address
          }}</el-descriptions-item>
          <el-descriptions-item label="联系人A">
            {{ billInfo.first_relation_type }}：{{
              billInfo.first_relation_user_name
            }}
            {{ billInfo.first_relation_user_mobile }}
          </el-descriptions-item>
          <el-descriptions-item label="联系人B">
            {{ billInfo.second_relation_type }}：{{
              billInfo.second_relation_user_name
            }}
            {{ billInfo.second_relation_user_mobile }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card shadow="hover" class="cardInfo">
        <el-descriptions
          class="margin-top"
          labelClassName="labelc"
          contentClassName="connamc"
          title="账单信息"
          :column="2"
          size="medium"
          border
        >
          <el-descriptions-item label="账单号">{{
            billInfo.bill_no
          }}</el-descriptions-item>
          <el-descriptions-item label="账单状态">
            <span v-if="billInfo.bill_status == 1">待资料审核</span>
            <span v-if="billInfo.bill_status == 2">待分期确认</span>
            <span v-if="billInfo.bill_status == 3">审核拒绝</span>
            <span v-if="billInfo.bill_status == 4">生效中</span>
            <span v-if="billInfo.bill_status == 5">已逾期</span>
            <span v-if="billInfo.bill_status == 6">已完结</span>
            <el-button
              v-if="billInfo.bill_status == 7"
              type="text"
              @click="getCheckRedund"
              >已退款</el-button
            >
            <span v-if="billInfo.bill_status == 8">已取消</span>
          </el-descriptions-item>
          <el-descriptions-item label="商家名称">{{
            billInfo.full_name
          }}</el-descriptions-item>
          <el-descriptions-item label="购买产品">{{
            billInfo.product_name
          }}</el-descriptions-item>
          <el-descriptions-item label="申请金额">{{
            billInfo.apply_repayment_principal
          }}</el-descriptions-item>
          <el-descriptions-item label="申请时间">{{
            billInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="放款金额">
            {{ billInfo.reality_repayment_principal }}
          </el-descriptions-item>
          <el-descriptions-item label="放款时间">{{
            billInfo.create_time
          }}</el-descriptions-item>
          <el-descriptions-item label="分期类型">
            {{ stageTypeList[billInfo.stage_type] }}
            <template v-if="billInfo.stage_type == 1">
              {{ billInfo.total_stage_count }}期</template
            >
            <template v-if="billInfo.stage_type == 2">
              {{ billInfo.x_payment_stage_count }}+{{
                billInfo.y_payment_stage_count
              }}期</template
            >
          </el-descriptions-item>
          <el-descriptions-item label="机构推荐码">{{
            billInfo.recommend_code
          }}</el-descriptions-item>
          <el-descriptions-item label="备注">
            <span v-if="billInfo.remark">{{ billInfo.remark }}</span>
            <span v-else>暂无备注</span>
            <div class="remark-btn">
              <el-button
                style="margin: 10px"
                type="primary"
                size="small"
                @click="onRemark"
                >修改备注</el-button
              >
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
    </div>
    <div class="deitem" v-if="activeName == '分期明细'">
      <el-button
        type="primary"
        @click="getApplyBillDelay"
        v-if="infoBusiness.dalay_day == 1"
        style="margin: 10px 0px"
        >申请延期</el-button
      >
      <el-date-picker v-if="changeStatus" type="date" placeholder="更改还款日期" @input="changeTime" value-format="dd"
       style="margin: 10px 10px;"></el-date-picker>
      <base-table
        :columns="costInfo"
        :data="billStageList"
        :pagination="searchForm"
        @getData="initData"
        :loading="loading"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          slot="combine_stage_type"
          label="分期类型"
          align="center"
          :formatter="stageTypeFormatter"
        ></el-table-column>
        <el-table-column
          slot="repayment_total"
          label="总额"
          align="center"
          :formatter="totalTypeFormatter"
        ></el-table-column>
        <el-table-column
          slot="is_overdue"
          label="是否逾期"
          align="center"
          :formatter="isOverdueFormatter"
        ></el-table-column>
        <el-table-column
          slot="is_delay_stage"
          label="是否延期"
          align="center"
          :formatter="isDelayStageFormatter"
        ></el-table-column>
        <el-table-column
          slot="payment_status"
          label="状态"
          align="center"
          :formatter="stageStatus"
        ></el-table-column>
      </base-table>
    </div>

    <approve-modal ref="approveModal" @approveConfim="getApproveConfim" />
    <refund ref="refund" />
    <refund-detail ref="RefundDetail" />
    <billRemark ref="billRemark" />
    <apply-bill-delay ref="applyBillDelay" />
    <div
      v-loading.fullscreen.lock="fullscreenLoading"
      element-loading-text="正在审核中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    ></div>
  </div>
</template>

<script>
import { billDetail, billApprove, cancelRefund, billDateChange, getBillDateState } from "@/api/bill.js";
import columns from "./columns";
import approveModal from "./components/approve-modal.vue";
import refund from "./components/refund.vue";
import { infoBusiness } from "@/api/business.js";
import RefundDetail from "./components/refund-detail.vue";
import billRemark from "./components/bill-remark.vue";
import ApplyBillDelay from "./components/apply-Bill-Delay.vue";

export default {
  components: {
    approveModal,
    refund,
    RefundDetail,
    billRemark,
    ApplyBillDelay,
  },
  name: "billInfo",

  data() {
    return {
      ...columns,
      billNo: "",
      // 账单列表
      billInfo: {},
      // 审核列表
      approveList: {},
      // 分期列表
      billStageList: {},
      activeName: "账单信息",
      stageTypeList: {
        0: "全款",
        1: "等额还款",
        2: "组合还款",
      },
      loading: false,
      searchForm: {},
      finishStatus: {
        0: "wait",
        1: "success",
        2: "error",
      },
      fullscreenLoading: false,
      infoBusiness: {},
      billRefundList: [],
      changeStatus: false,
      isApproveAuth: false,
      isRefundAuth: false
    };
  },

  async mounted() {
    this.billNo = this.$route.query.billNo;
    if (this.billNo) {
      await this.initData();
      this.getInfoBusiness();
    } else {
      this.$router.replace("/bill/billList");
    }
  },

  methods: {
    changeTime(val) {
      console.log(val)
      this.changeBillState(val)
    },
    async changeBillState(val) {
      const param = {
        billId: this.billInfo.bill_id,
        date: val
      };
      const { code, data } = await billDateChange(param);
      if (code == 200) {
        // 成功
        console.log(data)
      } else if (code == 20002) {
        // 谈吐司
      }
    },
    async initData() {
      let that = this
      JSON.parse(localStorage.getItem('__auth__')).map(item => {
        if (item.auth_title == '账单审核') {
          that.isApproveAuth = true;
        }
        if (item.auth_title == '账单退费') {
          that.isRefundAuth = true;
        }
      })
      const params = {
        billNo: this.billNo,
      };
      const { code, data } = await billDetail(params);
      if (code == 200) {
        this.billInfo = data.billInfo;
        var i = 0;
        this.billStageList = data.billStageList.map((item) => {
          if (item.early_repayment_fees != '0.00') {
            item.current_stage = '部分还款'
          } else {
            item.current_stage = ++i
          }
          return item;
        });
        this.approveList = data.approveList;
        this.billRefundList = data.billRefundList;
      }
      this.getChangeStatus()
    },
    async getChangeStatus() {
      const param = {
        billNo: this.billNo,
      }
      const {code, data} = await getBillDateState(param)
      if (code == 200) {
        this.changeStatus = data.change_status
      }
    },
    onRemark() {
      this.$refs.billRemark.isShow(this.billInfo.bill_no, this.billInfo.remark);
    },
    getCheckRedund() {
      this.$refs.RefundDetail.isShow(this.billRefundList);
    },
    getApplyBillDelay() {
      this.$refs.applyBillDelay.isShow(this.billInfo.bill_no);
    },
    async getInfoBusiness() {
      const param = {
        businessId: this.billInfo.business_id,
      };
      const { code, data } = await infoBusiness(param);
      if (code == 200) {
        this.infoBusiness = data;
      }
    },
    onRefund() {
      this.$refs.refund.isShow(
        this.billInfo.bill_no,
        this.billInfo.refund_rate
      );
    },
    onCancelRefund() {
      let that = this;
      this.$confirm("是否取消退款？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const params = {
            billNo: this.billInfo.bill_no,
          };
          const { code } = await cancelRefund(params);
          if (code == 200) {
            that.$message({
              type: "success",
              message: "操作成功!",
            });
            that.initData();
          }
        })
        .catch(() => {});
    },
    onApproveData(item) {
      this.$refs.approveModal.isShow("审核资料", item, this.billNo);
    },
    onApproveInstitution(item) {
      this.$refs.approveModal.isShow("机构审核", item, this.billNo);
    },
    async getApproveConfim(params) {
      this.fullscreenLoading = true;
      const { code } = await billApprove(params);
      if (code == 200) {
        this.fullscreenLoading = false;
        this.$refs.approveModal.close();
        this.initData();
        this.$message.success("操作成功");
      } else {
        this.fullscreenLoading = false;
      }
    },
    handleClick(tab) {
      this.activeName = tab.name;
    },
    stageTypeFormatter(row) {
      switch (row.combine_stage_type) {
        case 0:
          return "等额本金";
        case 1:
          return "X期";
        case 2:
          return "Y期";
      }
    },
    totalTypeFormatter(row) {
      return (Number(row.repayment_principal) + Number(row.overdue_late_fee) + Number(row.overdue_fine)).toFixed(2)
    },
    overdueLateFee(row) {
      console.log(row)
      return row.overdue_late_fee
    },
    stageStatus(row) {
      if (row.payment_status == 3) {
        return "已退费";
      }
      if (row.payment_status == 2) {
        return "已还款";
      }
      if (row.payment_status == 1) {
        return "部分还款";
      }
      if (row.payment_status == 0) {
        return "未还款";
      }
      return "";
    },
    isOverdueFormatter(row) {
      switch (row.is_overdue) {
        case 0:
          return "";
        case 1:
          return "是";
      }
    },
    isDelayStageFormatter(row) {
      switch (row.is_delay_stage) {
        case 0:
          return "";
        case 1:
          return "是";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.billInfo {
  .opdetcru {
    margin-bottom: 20px;
  }
  .tabbarsa {
    margin-bottom: 16px;
  }
  .basic-info {
    .user-info {
      display: flex;
      align-items: center;
      .head-pic {
        font-size: 30px;
        margin-right: 10px;
      }
      .head-bd {
        align-items: center;
        .name {
          font-size: 16px;
        }
      }
    }
    .tip-info {
      display: flex;
      margin-left: 20px;
    }
  }
  .deitem {
    .cardInfo {
      margin-top: 20px;
      .remark-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
    .progress {
      display: flex;
      .prosteps {
        width: 50%;
        min-width: 500px;
        margin-top: 20px;
        margin-right: 20px;
      }
      .refundInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .refundTitle {
          margin: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .already-redund {
          .titlt {
            display: flex;
            align-items: center;
            .checkRedund {
              margin: 10px;
              padding: 5px 10px;
              cursor: pointer;
              background: #169bd5;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-tabs__content {
  overflow: visible;
  border: 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 0;
}
::v-deep .el-tabs__item {
  color: #515a6e;
  background-color: #f8f8f9;
  margin-right: 5px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: 1px solid #e4e7ed;
  border-radius: 5px 5px 0px 0px;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-color: #57a3f3;
  border-bottom-color: #fff;
}
::v-deep .el-tabs__item.is-active {
  color: #57a3f3;
  background-color: #fff;
  border-color: #57a3f3;
}
</style>
<style>
.labelc {
  width: 25% !important;
  text-align: center !important;
  color: #495060 !important;
  font-weight: 700 !important;
  background-color: #f8f8f9 !important;
}
.connamc {
  width: 25% !important;
}
</style>
