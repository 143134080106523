<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="120px">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入"
            v-model="form.remark"
            :autosize="{ minRows: 4, maxRows: 4 }"
          >
          </el-input>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { billRemark } from '@/api/bill.js'
export default {
  name: "refund",

  data() {
    return {
      title: "账单备注",
      dialogFormVisible: false,
      form: {
        billNo: "",
        remark: '',
      },
    };
  },

  mounted() {},

  methods: {
    async isShow(billNo, remark) {
      this.dialogFormVisible = true;
      this.form.billNo = billNo;
      this.form.remark = remark;
    },
    async confirm() {
      console.log(this.form);
      const {code} = await billRemark(this.form)
      if(code == 200) {
        this.$message.success("操作成功");
        this.close();
        this.$parent.initData();
      }
    },
    close() {
      this.dialogFormVisible = false;
      this.form = {
        billNo: "",
        remark: '',
      }
    },
    handleClose(done) {
      done();
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .customW {
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title {
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer {
    border-top: 1px solid #e8eaec;
  }
}
</style>